import {axiosInstance} from "../http-common";

const get = () => {
  return axiosInstance.get("user/");
};

const update = (id, data) => {
  return axiosInstance.put("/user/", data);
};

const userIsVerified= (user) => {
  if(user?.verifications.mobilePhoneNumberIsVerified && user?.verifications.socialSecurityNumberIsVerified && user?.verifications.paymentMethodIsVerified){
    return true;
  }
  return false;
}

export default {
  get,
  update,
  userIsVerified
};