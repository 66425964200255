import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core/styles";
import 'date-fns';
import WithLoading from 'hoc/WithLoading';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useVehicleAvailable } from 'hooks/useVehicleAvailable';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    paper: {
        minWidth: 400,
    }
}));

const selectedDate = ()=>{
    var storedSearch = JSON.parse(localStorage.getItem('storedSearch'));
    var storedDate = new Date(storedSearch?.startDate);
    if(storedDate < new Date()){
        return new Date();
    }
    return storedDate;
}

const VehicleItem = (props) => {
    var { vehicle, ...other } = props;
    const [selectedDateTime, setSelectedDateTime] = useState();

    const {
        isAvailable,
        availableText,
        futureBookings,
        error
      } = useVehicleAvailable(vehicle.id, selectedDateTime, []);

    useEffect(() => {
        setSelectedDateTime(selectedDate());
    }, []);

    return (
        <WithLoading isLoading={vehicle == null}>
            <ListItem button component={Link} to={"/car/" + vehicle.id} {...other}>
                <ListItemAvatar>
                    <Avatar alt="Bild" src={vehicle?.vehicleModel.imageUrls[0]} />
                </ListItemAvatar>
                <ListItemText primary={vehicle?.vehicleModel.modelName} secondary={vehicle?.regNo} />
                <Chip icon={isAvailable ? <DoneIcon /> : <CloseIcon />} variant="outlined" color="primary" label={availableText} />
            </ListItem>
        </WithLoading>
    )
}
export default VehicleItem