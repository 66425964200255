import * as Yup from 'yup';

export const userValidation = Yup.object({
    firstName: Yup.string().required("*"),
    lastName: Yup.string().required("*"),
    email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),

    address: Yup.object({
        street1: Yup.string().required("*"),
        postalCode: Yup.string().required("*"),
        postalAddress: Yup.string().required("*"),
        country: Yup.string().required("*")
    })
});