import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
      },
}));

export default function CarDetailsDialog(props) {
    const classes = useStyles();
    const {title, subheader, buttonText} = props;

    return(
        <Grid item key={title} xs={12} md={4}>
        <Card>
          <CardHeader
            title={title}
            subheader={subheader}
            titleTypographyProps={{ align: 'center' }}
            subheaderTypographyProps={{ align: 'center' }}
            className={classes.cardHeader}
          />
          <CardContent>
            {props.children}
          </CardContent>
          <CardActions>
            <Button fullWidth color="primary">
              {buttonText}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
}