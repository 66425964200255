import {axiosInstance} from "../http-common";

const get = () => {
  return axiosInstance.get("/vehicleHomes/");
};

const getById = (id) => {
  return axiosInstance.get("/vehicleHomes/" + id);
};

const getVehiclesInHome = (id) => {
    return axiosInstance.get("/vehicleHomes/" + id + "/vehicles");
  };

export default {
  get,
  getById,
  getVehiclesInHome
};