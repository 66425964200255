import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'formik';
import { FormikTextField } from 'components/forms/FormikTextField'
import { default as React } from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
      marginTop: theme.spacing(3),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    }
  }));

export const SignupForm = () => {
    const classes = useStyles();

    return (
        <Form className={classes.form}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormikTextField 
                        variant="outlined"
                        label="Förnamn"
                        name="firstName"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormikTextField 
                        variant="outlined"
                        label="Efternamn"
                        name="lastName"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextField 
                        variant="outlined"
                        label="E-mail"
                        name="email"
                    />
                </Grid>                             
                <Grid item xs={12}>
                    <FormikTextField 
                        variant="outlined"
                        label="Lösenord"
                        name="password"
                        type="password"
                    />
                </Grid>                                        
            </Grid>  
            <Button variant="contained" color="primary" fullWidth type="submit" value="Submit" className={classes.button}>
                Bli medlem
            </Button>
        </Form>
    );
};