/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import LockOpenIcon from '@material-ui/icons/LockOpen';

// @material-ui/icons
import { AccountCircle, ViewList, DriveEta, Home } from "@material-ui/icons";

// core components
import Button from "@material-ui/core/Button";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
      <Button
          href="/signin"
          className={classes.navLink}
        >
          <LockOpenIcon className={classes.icons} />Logga in
        </Button>
        <Button
          href="/signup"
          className={classes.navLink}
        >
          <DriveEta className={classes.icons} /> Bli medlem
        </Button>
      </ListItem>
    </List>
  );
}
