import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from 'components/alert/alert';
import Copyright from 'components/Layout/Copyright';
import Background from 'components/Layout/Background';
import { useInput } from 'hooks/useInput';
import { default as React, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useHistory } from 'react-router-dom';
import AuthService from "services/auth.service";

const useStyles = makeStyles((theme) => ({
  card:{
    marginTop: theme.spacing(14),
  },
  button:{
    marginBottom: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
    const classes = useStyles();
    const { value:login, bind:bindLogin, reset:resetLogin } = useInput('');
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        AuthService.login(login, password).then(
            () => {
              window.location.href = "/start";
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              setLoading(false)
              setMessage(resMessage);
            }
        );
      }
  
    const handleFacebookResponse = (response) => {
        alert(JSON.stringify(response));
        AuthService.loginFacebook(response.accessToken).then(
        () => {
              window.location.href = "/start";
            },
            error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
    
            setLoading(false)
            setMessage(resMessage);
            }
        );
    }    

  return (
    <Container component="main" maxWidth="xs">
      <Background />
      <CssBaseline />
        <Card className={classes.card}>
          <CardContent>
          <span className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit}
              >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                {...bindLogin}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                {...bindPassword}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
                >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Sign In
                </Button>          
              <FacebookLogin
                appId="3313931901988954"
                
                autoLoad={false}
                color="default"
                callback={handleFacebookResponse}
                fields="email"
                render={renderProps => (
                  <Button fullWidth variant="contained" color="secondary"  onClick={renderProps.onClick} className={classes.button}>Login with Facebok</Button>
                )} />    
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </span>
          </CardContent>
        </Card>
      <Box mt={2}>
        <Copyright />
      </Box>
      <Alert open={message!=null} severity="error" onClick={() => setMessage(null)}>
                {message}
      </Alert>
    </Container>
  );
}