import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DirectionsIcon from '@material-ui/icons/Directions';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CarpoolDetailsDialog from 'components/dialogs/carpoolDetailsDialog';
import React from 'react';

const VehicleHomeWithPopup = (props) => {
    var { vehicleHome, ...other } = props;
    const [carpoolDetailsOpen, setCarpoolDetailsOpen] = React.useState(false);
    return (
        <React.Fragment>
            {vehicleHome &&
                <React.Fragment>
                    <ListItem key="vehicleHome" button onClick={()=>setCarpoolDetailsOpen(true)}>
                        <ListItemIcon>
                            <LocationOnOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={vehicleHome.name} secondary={vehicleHome.street + " - " + vehicleHome.postalAdress } />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="directions" href={"https://maps.google.com?daddr=" + vehicleHome.position.latitude + "," + vehicleHome.position.longitude} target="_blank">
                                <DirectionsIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <CarpoolDetailsDialog
                        id="pool-dialog"
                        keepMounted
                        open={carpoolDetailsOpen}
                        onClose={()=>setCarpoolDetailsOpen(false)}
                        vehicleHomeId={vehicleHome.id}
                    /> 
                </React.Fragment>
            }
        </React.Fragment>
    )
}
export default VehicleHomeWithPopup