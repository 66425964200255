/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { AccountCircle, ViewList, DriveEta, Home } from "@material-ui/icons";

// core components
import Button from "@material-ui/core/Button";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
      <Button
          component={Link} 
          to={"/start"}
          className={classes.navLink}
        >
          <Home className={classes.icons} /> Start
        </Button>
        <Button
          component={Link} 
          to={"/book-car"}
          className={classes.navLink}
        >
          <DriveEta className={classes.icons} /> Boka bil
        </Button>
        <Button
          component={Link} 
          to={"/bookings"}
          className={classes.navLink}
        >
          <ViewList className={classes.icons} /> Bokningar
        </Button>
        <Button
          component={Link} 
          to={"/profile-page"}
          className={classes.navLink}
        >
          <AccountCircle className={classes.icons} /> Profil
        </Button>
      </ListItem>
    </List>
  );
}
