import * as Yup from 'yup';

export const signupValidation = Yup.object({
    firstName: Yup.string().required("*"),

    lastName: Yup.string().required("*"),

    email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),

    password: Yup.string().required("*"),
});