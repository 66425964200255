import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TuneIcon from '@material-ui/icons/Tune';
import Alert from 'components/alert/alert';
import Background from 'components/Layout/Background';
import PageTitle from 'components/Layout/PageTitle';
import { useVehicleData } from 'hooks/useVehicleData';
import BookCarFilter from 'pages/BookCar/BookCarFilters';
import VehicleHomeMap from 'pages/CarsNear/VehicleHomeMap';
import { default as React, useState, useEffect } from 'react';
import VehicleHomeList from "./VehicleHomeList";
import { getDistinctPropertyOfObject } from 'helpers/general';
import { getDistance } from 'ol/sphere';
import InfoDialog from 'components/dialogs/infoDialog'

const useStyles = makeStyles((theme) => ({
    carpoolList: {
        height: "600px",
        overflowX: "hidden",
        overflowY: "scroll",
        backgroundColor:"white",
        [theme.breakpoints.down('sm')]: {
            overflowY: 'scroll',
            height: '30vh'
        }
    },
    carpoolMap: {
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            overflow: 'hidden',
            height: 'calc(70vh - 150px)'
        },
    },
    fab: {
        float: 'right',
        bottom: '70px',
        right: '15px',
        [theme.breakpoints.down('sm')]: {
            float: 'none',
            position: 'absolute',
            right: '30px',
            bottom: '90px'
        },
    }
}));
  
export default function CarsNearPage(props) {
    const classes = useStyles();
    const {
        vehicles,
        vehicleHomes,
        availableFuels,
        availableCarModels,
        availableFeatures,
        error,
      } = useVehicleData();
    const [selectedVehicleHomeId, setSelectedVehicleHomeId] = useState(0);
    const [userPosition, setUserPosition] = useState();
    const [filteredVechicleHomes, setFilteredVechicleHomes] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const numberOfPoolsToShow = 50;

    const [message, setMessage] = useState(error);
    const [severity, setSeverity] = useState("error");



    const handleFiltersChange = (search) =>{
        setUserPosition({
            lat: search.lat,
            lon: search.lon
        })
        //Filter cars
        var filteredCars = vehicles;
        if(search.carModels && search.carModels.length > 0){
            let tmp = vehicles.filter(p=>search.carModels.includes(p.vehicleModel.modelName));
            if(tmp.length > 0){
                filteredCars = tmp;
            }
        }
        if(search.fuel){
            let tmp = vehicles.filter(p=>p.vehicleModel.fuelType == search.fuel);
            if(tmp.length > 0){
                filteredCars = tmp;
            }
        }
        if(search.features){
            let tmp = vehicles.filter(p=>p.features.some(item => item.description == search.features));
            if(tmp.length > 0){
                filteredCars = tmp;
            }
        }
        var uniqHomes = getDistinctPropertyOfObject(filteredCars, "vehicleHome", "id");
        uniqHomes = uniqHomes.map(item=>{
            item.distance = distanceToPool(item.position, search);
            return item;
        });
        uniqHomes = uniqHomes.sort((a, b) => (a.distance > b.distance) ? 1 : -1);
        setFilteredVechicleHomes(uniqHomes.slice(0, numberOfPoolsToShow)); 
    }

    const distanceToPool = function(position, search){
        if(!search.lat || !search.lon){
            return "";
        }

        var length = getDistance([position?.longitude, position?.latitude],[search.lon, search.lat] );
        return length;
    }
    
    const onMarkerClick = (data) => {
        setSelectedVehicleHomeId(data.get('id'));
    };

    useEffect(() => {
        if(vehicles?.length > 0){
            handleFiltersChange(JSON.parse(localStorage.getItem('storedSearch')));
        }
    },[vehicles]);

    return (
        <Container maxWidth="lg">
            <Background />
            <PageTitle title="Hitta en plats" />
            <Grid container>
                <Grid item md={4} xs={12} className={classes.carpoolList}>
                    <VehicleHomeList vehicleHomes={filteredVechicleHomes} selectedVehicleHomeId={selectedVehicleHomeId} />
                </Grid>
                <Grid item md={8} xs={12} className={classes.carpoolMap}>
                    <VehicleHomeMap vehicleHomes={filteredVechicleHomes} onMarkerClick={onMarkerClick} userPosition={userPosition} />
                    <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => setDialogOpen(true)}>
                        <TuneIcon />
                    </Fab>
                </Grid>
            </Grid>
            <InfoDialog open={dialogOpen} onClose={() => setDialogOpen(false)} removeContainer={true} onCancel={() => setDialogOpen(false)} header="Filter">
                <BookCarFilter fuels={availableFuels} carModels={availableCarModels} features={availableFeatures} onSearchChange={handleFiltersChange} />
            </InfoDialog>
            <Alert open={message!=null} severity={severity} onClick={() => setMessage(null)}>
                {message}
            </Alert>                
        </Container>
    );
}

