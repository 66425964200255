import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from "@material-ui/core/styles";
import 'date-fns';
import WithLoading from 'hoc/WithLoading';
import React from 'react';
import { Link } from 'react-router-dom';

const VehicleHomeItem = (props) => {
    var { vehicleHome, ...other } = props;

    const formatDistance = function(length) {
        var output;
        if (length > 100) {
          output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
        } else {
          output = Math.round(length * 100) / 100 + ' ' + 'm';
        }
        return output;
    }

    return (
        <WithLoading isLoading={vehicleHome == null}>
            <ListItem button component={Link} to={"/vehicleHome/" + vehicleHome?.id} {...other}>
                <ListItemAvatar>
                    <Avatar alt="Bild" src={vehicleHome?.imageUrls[0]} />
                </ListItemAvatar>
                <ListItemText primary={vehicleHome?.name} secondary={vehicleHome?.street} />
                <ListItemText
                    primary="&nbsp;"
                    secondary={
                        <React.Fragment>
                            <span style={{ float: "right" }}>{formatDistance(vehicleHome?.distance)}</span>
                        </React.Fragment>
                    }
                />
            </ListItem>
        </WithLoading>
    )
}
export default VehicleHomeItem