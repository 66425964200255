export const getDistinctPropertyOfObject = (object, property, uniqueKey, flatten) => 
{
    var tmp = [...new Set(object?.map(item => {
        var parts = property.split('.');
        while(parts.length > 0){
            item = item[parts.shift()]
        }
        return item;
    }))];
    if(!uniqueKey)
    {
        return tmp;
    }

    if(flatten){
        tmp = tmp.filter(p=>p.length > 0)
        tmp = tmp.flat(3);
    }

    var uniq = Array.from(new Set(tmp?.map(a => a[uniqueKey])))
    .map(id => {
      return tmp.find(a => a[uniqueKey] === id)
    });

    return uniq;
}

Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}