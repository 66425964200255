import React from 'react';
import { useField } from "formik";
import { TextField } from '@material-ui/core';

export const FormikTextField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props)
    return <TextField
        id={field.name}
        name={field.name}
        label={label}
        type="text"
        fullWidth
        error={meta.touched && Boolean(meta.error)}
        onChange={field.onChange}
        helperText={meta.touched ? meta.error : ""}
        value={field.value}
        {...props}
    />
}