import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
}));

export default function SimpleHero(props) {
    const classes = useStyles();
    const {header, text} = props;

    return(
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                {header}
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" component="p">
                {text}
            </Typography>
        </Container>
    );
}