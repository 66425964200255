import { CardContent } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from 'components/alert/alert';
import Copyright from 'components/Layout/Copyright';
import { SignupForm } from 'components/forms/signupForm';
import { Formik } from 'formik';
import { default as React, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from 'services/auth.service';
import { signupValidation } from 'validation/signupValidation';
import Background from 'components/Layout/Background';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    card:{
        marginTop: theme.spacing(14),
    },
}));


export default function SignUpPage() {
    const classes = useStyles();
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState("error");
    const history = useHistory();

    const registerUser = (user) => {
        AuthService.register(user.email, user.password, user.firstName, user.lastName)
          .then(response => {
            history.push("/signin");
          })
          .catch(e => {
            console.log(e);
            setSeverity("error");
            setMessage("Tyvärr misslyckades vi att spara dina uppgifter, försök igen");
          });
    };

    const [activeStep, setActiveStep] = React.useState(0);

    return (
        <Container component="main" maxWidth="xs">
            <Background />
            <CssBaseline />
            <Card className={classes.card}>
                <CardContent>
                    <span className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                        <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                    firstName: '',
                                    lastName: ''
                                }}
                                validationSchema={signupValidation}
                                onSubmit={(values) => {
                                    registerUser(values);
                                }}
                            >
                                <SignupForm />
                        </Formik>
                    </span>
                </CardContent>
            </Card>
            <Box mt={2}>
                <Copyright />
            </Box>
            <Alert open={message!=null} severity={severity} onClick={() => setMessage(null)}>
                {message}
            </Alert>
        </Container>
    );
}