import { Chip, Input, InputLabel, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { Done, PhoneAndroid } from '@material-ui/icons';
import InfoDialog from 'components/dialogs/infoDialog';
import PhoneMask from 'components/masks/phoneMask';
import { default as React } from 'react';

export default function VerifyPaymentMethod(props){
    const { paymentMethodIsVerified: valueProp, hideText, ...other } = props;
    const [paymentMethodIsVerified, setPaymentMethodIsVerified] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    React.useEffect(() => {
        setPaymentMethodIsVerified(valueProp);
    }, [valueProp]);

    return(
        <React.Fragment>
            {!paymentMethodIsVerified
                &&
                <React.Fragment>
                    {!hideText &&
                        <Typography variant="body1" gutterBottom>
                            Lägg till ett betalsätt
                        </Typography>
                    }                    
                    <Button variant="contained" color="primary" onClick={()=>setOpenDialog(true)}>Lägg till betalsätt</Button>
                    <InfoDialog open={openDialog} onClose={() => setOpenDialog(false)} onCancel={() => setOpenDialog(false)}   header="Lägg till betalsätt">
                        <React.Fragment>
                            <Typography variant="body1" gutterBottom>
                                Just nu går det inte lägga till nya betalsätt
                            </Typography>
                        </React.Fragment>
                    </InfoDialog>
                </React.Fragment>
            }
        </React.Fragment>
    );
}