import Container from '@material-ui/core/Container';
import Background from 'components/Layout/Background';
import PageTitle from 'components/Layout/PageTitle';
import React, { Component } from 'react';
import CarItem from "./Caritem";

export class CarPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <span>
                <Container maxWidth="md">
                    <Background />
                    <PageTitle title={"Boka"} />
                    <CarItem carId={this.props.match.params.carId} />
                </Container>
            </span>
        );
    }
}

export default CarPage;