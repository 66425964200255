import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';

const ImportantInfo = (props) => {
    var { booking, ...other } = props;
    return (
        <React.Fragment>
            {(booking?.vehicleBookingInfo || booking?.vehicleHomeBookingInfo) && 
                <Box marginTop={2} marginBottom={2} >
                    <Alert severity="info">
                        <AlertTitle>Viktig information</AlertTitle>
                        <ul>
                        {booking?.vehicleBookingInfo && <li key="vehicleBookingInfo">{booking?.vehicleBookingInfo}</li>}
                        {booking?.vehicleHomeBookingInfo && <li key="vehicleHomeBookingInfo">{booking?.vehicleHomeBookingInfo}</li>}
                        </ul>
                    </Alert>
                </Box>
            }  
        </React.Fragment>
    )
}
export default ImportantInfo