import React from 'react';

const backgroundImage = 'static/road.jpg';

export default function SlidingAppBar(props) {
    return (
        <React.Fragment>
            <div style={{
                backgroundColor: "black",
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                opacity: 0.5,
                zIndex: -1,
            }} ></div>
            <div style={{
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: '#000', // Average color of the background image.
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                zIndex: -2,
            }}>
            </div>
        </React.Fragment>
    );
}