import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LockIcon from '@material-ui/icons/Lock';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden'
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function InfoBoxes(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <img
          src="/static/appCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <span className={classes.item}>
              <DriveEtaIcon style={{ fontSize: 50 }} />
              <Typography variant="h6" className={classes.title}>
                Enkelt
              </Typography>
              <Typography variant="h6">
                {'Med Bilbokaren är det enkelt att hitta en ledig bil'}
              </Typography>
            </span>
          </Grid>
          <Grid item xs={12} md={4}>
            <span className={classes.item}>
              <LockIcon style={{ fontSize: 50 }}  />
              <Typography variant="h6" className={classes.title}>
                Flexibelt
              </Typography>
              <Typography variant="h6">
                {'Det går att använda flera olika hårdvaror för att kommunicera med bilarna.'}
              </Typography>
            </span>
          </Grid>
          <Grid item xs={12} md={4}>
            <span className={classes.item}>
              <FilterDramaIcon style={{ fontSize: 50 }}  />
              <Typography variant="h6" className={classes.title}>
               Flexibelt
              </Typography>
              <Typography variant="h6">
                {'Det är roligt när det är så enkelt att boka bil'}
              </Typography>
            </span>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

InfoBoxes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoBoxes);