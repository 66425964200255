import { FormHelperText, Input, InputAdornment, InputLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import { Cancel, Check, Description } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PaymentIcon from '@material-ui/icons/Payment';
import SubjectIcon from '@material-ui/icons/Subject';
import UpdateIcon from '@material-ui/icons/Update';
import Alert from 'components/alert/alert';
import ImportantInfo from 'components/alert/ImportantInfo';
import InfoDialog from 'components/dialogs/infoDialog';
import WhenDialog from "components/dialogs/whenDialog";
import CarWithPopup from 'components/listItems/CarWithPopup';
import VehicleHomeWithPopup from 'components/listItems/VehicleHomeWithPopup';
import PhoneMask from 'components/masks/phoneMask';
import VerifyAlert from 'components/verification/VerifyAlert';
import VerifyMobile from 'components/verification/VerifyMobile';
import 'date-fns';
import { addHours } from 'date-fns';
import WithLoading from 'hoc/WithLoading';
import Moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BookingService from 'services/booking.service';
import UserService from 'services/user.service';
import VehicleService from 'services/vehicle.service';
import AvailableText from './AvailableText';

const CarItem = (props) => {
        Moment.locale('sv');
        const history = useHistory();
        
        const [openFromDateDialog, setOpenFromDateDialog] = React.useState(false);
        const [openToDateDialog, setOpenToDateDialog] = React.useState(false);
        const [verificationDialog, setVerificationDialog] = useState(false);
        const [car, setCar] = useState();
        const [user, setUser] = useState();
        const [message, setMessage] = useState(null);
        const [severity, setSeverity] = useState("error");

        const [booking, setBooking] = React.useState(
        {
            id: 0,
            startTime: new Date(),
            stopTime: addHours(new Date(), 2),
            vehicleId: 0,
            driverNote: "",
            paymentMethod: ""
        });

        const commitBooking = () => {
            if(!verifyUser(user)){
                return;
            }
            if(!validateBooking()){
                return false;
            }
            BookingService.commit(booking.id, booking)
              .then(response => {
                history.push("/bookings");
              })
              .catch(e => {
                if(e?.response?.status == '400'){
                    var errorMessage = "";
                    for (var key in e.response.data) {
                        if (e.response.data.hasOwnProperty(key)) {
                            errorMessage += key + ": " + e.response.data[key] + "\n";
                        }
                    }
                    setMessage(errorMessage);
                }else{
                    console.log(e);
                    setMessage("Tyvärr misslyckades vi att spara dina uppgifter, försök igen");
                }
                setSeverity("error");
              });
        };

        const validateBooking = () => {
            var validPaymentMethod = user.paymentMethods.filter(i => i.value == booking.paymentMethod);
            if(validPaymentMethod.length == 1){
                return true;
            }
            return false;
        }

        const handleInputChange = (e) => {
            const name = e.target.name
            const fieldValue = e.target.value
      
            setBooking(prevState => ({
              ...prevState,
              [name]: fieldValue
            }))
        }

        const handleFromClick = () => {
            setOpenFromDateDialog(true);
        };

        const handleFromClose = (newValue) => {
            setOpenFromDateDialog(false);

            if (newValue) {
                setBooking(prevState => ({
                    ...prevState,
                    startTime: newValue,
                  }))
                if (newValue > new Date(booking.stopTime)) {
                    setBooking(prevState => ({
                        ...prevState,
                        stopTime: addHours(new Date(newValue), 2),
                      }))
                }
            }
        };

        const handleToClick = () => {
            setOpenToDateDialog(true);
        };

        const handleToClose = (newValue) => {
            setOpenToDateDialog(false);

            if (newValue) {
                setBooking(prevState => ({
                    ...prevState,
                    stopTime: newValue,
                  }))
                if (newValue < new Date(booking.startTime)) {
                    setBooking(prevState => ({
                        ...prevState,
                        startTime: addHours(new Date(newValue), 2),
                      }))
                }
            }
        };

        const getCar = (id) => {
            VehicleService.get(id)
                .then(response => {
                    setCar(response.data);
                    setMessage(null);
                })
                .catch(e => {
                    handleError(e);
                });
        };

        const getBooking = (id) => {
            BookingService.get(id)
                .then(response => {
                    setBooking(response.data);
                    getCar(response.data.vehicleId);
                    setMessage(null);
                })
                .catch(e => {
                    handleError(e);
                });
        };

        const handleError = (e) => {
            setSeverity("error");
            setMessage("Problem att ansluta till servern, försök igen senare");
            console.log(e);
        }

        const getUser = () =>{
            UserService.get()
            .then(response => {
                setUser(response.data);
                setMessage(null);
            })
            .catch(e => {
                handleError(e);
            });
        }

        useEffect( () => {
            getUser();
            if(props.carId){
                getCar(props.carId);
                var storedSearch = JSON.parse(localStorage.getItem('storedSearch'));
                let startDate = new Date();
                if(storedSearch){
                    startDate = new Date(storedSearch.startDate);
                }
                setBooking(prevState => ({
                    ...prevState,
                    vehicleId: props.carId,
                    startTime: startDate,
                    stopTime: addHours(startDate, 2),
                  }));
            }
            if(props.bookingId){
                getBooking(props.bookingId);
            }
        },[]);

        const verifyUser = (user) => {
            if(user){
                if(!UserService.userIsVerified(user)){
                    // Show popup
                    setVerificationDialog(true);
                    return false;
                }
            }
            return true;
        };

        useEffect( () => {
            verifyUser(user);
            // Set default payment method if only used once
            if(user?.paymentMethods.length == 1){
                setBooking({...booking, paymentMethod: user?.paymentMethods[0].value});
            }
        },[user]);

        return (
            <Box mb={5}>
                <WithLoading isLoading={booking == null}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item md={6} sm={12} xs={12}>
                                    <img src={car?.vehicleModel?.imageUrls[0]} style={{width: '100%'}} />
                                    <span>
                                        <Chip variant="outlined" color="primary" label={car?.vehicleModel.fuelType} />
                                        {car?.features.map(function(feature){
                                            return (<Chip variant="outlined" key={feature.tag} color="primary" label={feature.description} />)
                                        })}
                                    </span>     
                                    <ImportantInfo booking={booking} />                   
                                </Grid>
                                <Grid item md={6} sm={12} xs={12} >
                                    <List>
                                        <CarWithPopup key="carWithPopup" car={car} />
                                        <Divider key="divider1" />
                                        <VehicleHomeWithPopup key="vehicleHomeWithPopup" vehicleHome={car?.vehicleHome} />
                                        <Divider key="divider2" />
                                        <CardActionArea key="cardActionArea1" onClick={handleFromClick}>
                                            <ListItem key="startDate">
                                                <ListItemIcon>
                                                    <AccessTimeIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Hämta" secondary={Moment(booking.startTime).format('D MMM - HH:mm')} />
                                                <AvailableText show="ifBooked" vehicleId={booking.vehicleId} currentBookingId={booking.id} dateTime={booking.startTime} />
                                            </ListItem>
                                        </CardActionArea>
                                        <Divider key="divider3"  />
                                        <CardActionArea onClick={handleToClick} key="cardActionArea2" >
                                            <ListItem key="stopDate">
                                                <ListItemIcon>
                                                    <UpdateIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Lämna" secondary={Moment(booking.stopTime).format('D MMM - HH:mm')} />
                                                <AvailableText show="ifAvailable" vehicleId={booking.vehicleId} currentBookingId={booking.id} dateTime={booking.stopTime} />                                           
                                            </ListItem>
                                        </CardActionArea>
                                        <Divider key="divider4"  />
                                        <ListItem key="payment" key="payment" >
                                            <ListItemIcon>
                                                <PaymentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Betalmetod" secondary={
                                                <FormControl fullWidth error={booking.paymentMethod == ""}>
                                                    <NativeSelect
                                                        onChange={handleInputChange}
                                                        value={booking.paymentMethod.value}
                                                        inputProps={{
                                                        name: 'paymentMethod',
                                                        id: 'pm-native',
                                                        }}
                                                    >
                                                        {user?.paymentMethods.length > 1 && <option key="" value="">Välj betalsätt</option>}
                                                        {user?.paymentMethods.filter(p=>p.type == "CreditCard") && 
                                                            <optgroup label="Betalkort">
                                                                {user?.paymentMethods.filter(p=>p.type == "CreditCard").map(function(pm){
                                                                    return (<option key={"CreditCard_" + pm.index} value={pm.value}>{pm.valueDescription}</option>)
                                                                })}
                                                            </optgroup>
                                                        }
                                                        {user?.paymentMethods.filter(p=>p.type == "Invoice") && 
                                                            <optgroup label="Faktura">
                                                                {user?.paymentMethods.filter(p=>p.type == "Invoice").map(function(pm){
                                                                    return (<option key={"Invoice_" + pm.index} value={pm.value}>{pm.valueDescription}</option>)
                                                                })}
                                                            </optgroup>
                                                        }

                                                    </NativeSelect>
                                                    <FormHelperText>Välj betalmetod</FormHelperText>
                                                </FormControl>
                                            } />
                                        </ListItem>
                                        <Divider key="divider5"  />
                                        <ListItem key="note" key="notes" >
                                            <ListItemIcon>
                                                <SubjectIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Notering" secondary={
                                                <TextField
                                                    id="standard-multiline-static"
                                                    name="driverNote"
                                                    fullWidth
                                                    onChange={handleInputChange}
                                                    value={booking.driverNote}
                                                />
                                            } />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                            <WhenDialog
                                id="from-dialog"
                                keepMounted
                                open={openFromDateDialog}
                                onClose={handleFromClose}
                                value={new Date(booking.startTime)}
                            />
                            <WhenDialog
                                id="to-dialog"
                                keepMounted
                                open={openToDateDialog}
                                onClose={handleToClose}
                                value={new Date(booking.stopTime)}
                            />
                            <InfoDialog open={verificationDialog} onClose={() => setVerificationDialog(false)} onCancel={() => setVerificationDialog(false)}   header="Komplettera profil">
                                <React.Fragment>
                                    <VerifyAlert user={user} />
                                </React.Fragment>
                            </InfoDialog>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Grid container justify="flex-end">
                                <Button color="secondary" onClick={()=>history.goBack()}>
                                    Avbryt
                                </Button>
                                <Button color="primary" variant="contained" onClick={commitBooking}>
                                    Boka
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
                </WithLoading>
                <Alert open={message!=null} severity={severity} onClick={() => setMessage(null)}>
                    {message}
                </Alert>
            </Box>
        )
}
export default CarItem