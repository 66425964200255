import { useState, useEffect } from 'react';
import VehicleService from 'services/vehicle.service';
import { getDistinctPropertyOfObject } from 'helpers/general';
import { getDistance } from 'ol/sphere';

export const useVehicleData = () => {

    const [vehicleData, setVehicleData] = useState({
        vehicles: [],
        vehicleHomes: [],
        availableFuels: [],
        availableCarModels: [],
        availableFeatures:[]
    });
    const [selectedLatitude, setSelectedLatitude] = useState(57.6800019);
    const [selectedLongitude, setSelectedLongitude] = useState(12.002703799999999);
    const [error, setError] = useState(null);

    const getCars = () => {
        VehicleService.getAll()
            .then(response => {
                updateVehicleHomes(response.data);
                setVehicleData({...vehicleData, 
                    vehicles: response.data,
                    availableFeatures: getDistinctPropertyOfObject(response.data, "features", "tag", true),
                    availableFuels: getDistinctPropertyOfObject(response.data, "vehicleModel.fuelType"),
                    availableCarModels: getDistinctPropertyOfObject(response.data, "vehicleModel.modelName"),
                });

                setError(null);
            })
            .catch(e => {
                onError(e);
            });
    };

    const updateVehicleHomes = (cars) => {
        var uniqHomes = getDistinctPropertyOfObject(cars, "vehicleHome", "id");

        // Sort pools by distance
        uniqHomes = uniqHomes.map(item=>{
            item.distance = distanceToPool(item.position);
            return item;
        });
        uniqHomes = uniqHomes.sort((a, b) => (a.distance > b.distance) ? 1 : -1);

        setVehicleData({...vehicleData, vehicleHomes: uniqHomes});
    }

    const distanceToPool = function(position){
        if(!selectedLatitude || !selectedLongitude){
            return "";
        }

        var length = getDistance([position?.longitude, position?.latitude],[selectedLongitude, selectedLatitude] );
        return length;
    }

    const onError = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        getCars();
    },[]);

    return { ...vehicleData, error };
};