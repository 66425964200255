import { Slider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CreditCard, Description, Dialpad, LocalGasStation, LocalParking } from '@material-ui/icons';
import SubjectIcon from '@material-ui/icons/Subject';
import UpdateIcon from '@material-ui/icons/Update';
import AlertCustom from 'components/alert/alert';
import ImportantInfo from 'components/alert/ImportantInfo';
import InfoDialog from 'components/dialogs/infoDialog';
import CarWithPopup from 'components/listItems/CarWithPopup';
import VehicleHomeWithPopup from 'components/listItems/VehicleHomeWithPopup';
import 'date-fns';
import { addMinutes } from 'date-fns';
import WithLoading from 'hoc/WithLoading';
import Moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import BookingService from 'services/booking.service';
import VehicleService from 'services/vehicle.service';

const ActiveBooking = (props) => {
    var { booking: bookingProp, ...other } = props;

    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState("error");

    const [booking, setBooking] = useState(bookingProp);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [car, setCar] = useState(null);
    const [extendHours, setExtendHours] = useState("1");

    React.useEffect(() => {
        setBooking(bookingProp);
    }, [bookingProp]);

    const getCar = (id) => {
      VehicleService.get(id)
          .then(response => {
              setCar(response.data);
              setMessage(null);
          })
          .catch(e => {
              handleError(e);
          });
    };
    
    useEffect( () => {
      if(booking?.vehicleId){
          getCar(booking.vehicleId);
      }
    },[booking]);

    const handleError = (e) => {
      setSeverity("error");
      setMessage("Problem att ansluta till servern, försök igen senare");
      console.log(e);
    }

    const handleExtendBooking = () => {
      setDialogOpen(false);
      let bookingUpdate = booking;
      bookingUpdate.stopTime = addMinutes(new Date(booking.stopTime), extendHours*60);
      setBooking(bookingUpdate);
      commitBooking(bookingUpdate);
    }

    const commitBooking = (bookingUpdate) => {
      BookingService.commit(bookingUpdate.id, bookingUpdate)
        .then(() => {
          setMessage("Bokningen har förlängts");
          setSeverity("Information");
        })
        .catch(e => {
              console.log(e);
              setMessage("Tyvärr misslyckades vi att spara dina uppgifter, försök igen");
              setSeverity("error");
          }
        );
    };

    function valuetext(value) {
      return `{value} H`;
    }

    const handleChange = (event, newValue) => {
      setExtendHours(newValue);
    };

    return (
      <Box mb={5}>
        <WithLoading isLoading={booking == null || car == null}>
          <Card>
              <CardContent>
                <Grid container>
                    <Grid item md={6} sm={12} xs={12}>
                        <img src={car?.vehicleModel?.imageUrls[0]} style={{width: '100%'}} />
                        <span>
                            <Chip variant="outlined" color="primary" label={car?.vehicleModel.fuelType} />
                            {car?.features.map(function(feature){
                                return (<Chip variant="outlined" key={"ab_chip_" + feature.tag} color="primary" label={feature.description} />)
                            })}
                        </span>      
                        <ImportantInfo booking={booking} />  
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} >
                        <List>
                            <CarWithPopup car={car} key="carwithpopup" />
                            <Divider key="divider1" />
                            <ListItem>
                              <ListItemIcon>
                                  <Dialpad />
                              </ListItemIcon>
                              <ListItemText primary="Pinkod till bilen" secondary={"PIN: XXXX"} />
                            </ListItem>
                            <Divider key="divider2"  />
                            {booking.cards.map(function(card){
                                return (
                                  <React.Fragment>
                                    <ListItem key={card.typeDescription}>
                                      <ListItemIcon>
                                          {card.type == 'Fuel' ? <LocalGasStation />: <LocalParking />}
                                      </ListItemIcon>
                                      <ListItemText primary={card.typeDescription + " - " + card.issuer } secondary={"PIN: " + card.pin} />
                                    </ListItem>
                                    <Divider key="divider2"  />
                                  </React.Fragment>
                                )
                            }
                            )}

                            <VehicleHomeWithPopup key="vehiclehomewithpopup"  vehicleHome={car?.vehicleHome} />
                            <Divider />
                            <ListItem key="startDate">
                                <ListItemIcon>
                                    <UpdateIcon />
                                </ListItemIcon>
                                <ListItemText primary="Hämta" secondary={Moment(booking.startDate).format('D MMM - HH:mm')}   />
                            </ListItem>                           
                            <Divider key="divider3" />
                            <ListItem key="stopDate">
                                <ListItemIcon>
                                    <UpdateIcon />
                                </ListItemIcon>
                                <ListItemText primary="Lämna" secondary={Moment(booking.stopTime).format('D MMM - HH:mm')} />
                            </ListItem>
                            <Divider key="divider4" />
                            <ListItem key="note">
                                <ListItemIcon>
                                    <SubjectIcon />
                                </ListItemIcon>
                                <ListItemText primary="Notering" secondary={booking.driverNote} />
                            </ListItem>
                            <Divider key="divider5" />
                            <ListItem key="paymentMethod">
                                <ListItemIcon>
                                    {booking.paymentMethod.type == 'Creditcard' ? <CreditCard /> : <Description />}
                                </ListItemIcon>
                                <ListItemText primary="Payment method" secondary={booking.paymentMethod.typeDescription + " - " + booking.paymentMethod.valueDescription} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container justify="flex-end">
                  <Button variant="contained"  color="primary" onClick={()=>{setDialogOpen(true)}}>
                    Förläng bokning
                  </Button>
                  <Button variant="contained"  color="secondary">
                    Avsluta bokning
                  </Button>
                </Grid>
              </CardActions>
            </Card>
            <InfoDialog open={dialogOpen} onClose={handleExtendBooking} onCancel={() => setDialogOpen(false)}   header="Förläng bokning">
              Förläng bokingen med {extendHours} timmar
              <Slider
                value={extendHours}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={0.5}
                marks 
                onChange={handleChange}
                min={0.5}
                max={8}
              />
            </InfoDialog>
          </WithLoading>
          <AlertCustom open={message!=null} severity={severity} onClick={() => setMessage(null)}>
                {message}
          </AlertCustom>
        </Box>
    )
}
export default ActiveBooking