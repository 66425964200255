import List from '@material-ui/core/List';
import VehicleHomeItem from './VehicleHomeItem';
import { default as React, useEffect, useState } from 'react';

const VehicleHomeList = (props) => {
    const { vehicleHomes, ...other } = props;

    return (
        <List>
            {vehicleHomes && vehicleHomes.map((vehicleHome)=>
                <VehicleHomeItem key={vehicleHome.id} vehicleHome={vehicleHome} selected={vehicleHome.id === props.selectedVehicleHomeId} autoFocus={vehicleHome.id === props.selectedVehicleHomeId} />
            )}
        </List>
    )
}
export default VehicleHomeList