import Fade from '@material-ui/core/Fade';
import PageTitle from 'components/Layout/PageTitle';
import BookingTabs from './BookingTabs';
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Background from 'components/Layout/Background'

export class BookingsPage extends Component {
    static displayName = BookingsPage.name;
    render() {
        return (
            <Container maxWidth="lg">
                <Background />
                <PageTitle title="Bokningar" />
                <BookingTabs />
            </Container>
        );
    }
}

export default BookingsPage;