import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CreditCard, Description } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormikTextField } from 'components/forms/FormikTextField';
import VerifyPaymentMethod from 'components/verification/VerifyPaymentMethod';
import { Form } from 'formik';
import { default as React } from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
      marginTop: theme.spacing(3),
    },
  }));

export const UserForm = (props) => {
    const classes = useStyles();
    const { user, ...other } = props;

    return (
        <Form>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom>
                        Personuppgifter
                    </Typography>   
                    <Grid container spacing={3}>
                        
                        <Grid item xs={12} sm={6}>
                            <FormikTextField 
                                label="Förnamn"
                                name="firstName"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikTextField 
                                label="Efternamn"
                                name="lastName"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikTextField 
                                label="E-mail"
                                name="email"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikTextField 
                                label="Mobil Nummer"
                                name="mobilePhoneNumber"
                                disabled
                            />
                        </Grid>                                
                        <Grid item xs={12} sm={6}>
                            <FormikTextField 
                                label="Personnummer"
                                name="socialSecurityNumber"
                                disabled
                            />
                        </Grid>                  
                    </Grid>  
                </Grid>   
 {/*               <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                        Adress
                    </Typography>       
                    <Grid container spacing={3}>   
                        <Grid item xs={12}>
                            <FormikTextField 
                                label="Attention"
                                name="address.attention"
                            />
                        </Grid>  
                        <Grid item xs={12}>
                            <FormikTextField 
                                label="Adress"
                                name="address.street1"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikTextField 
                                label="Adress fort"
                                name="address.street2"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikTextField 
                                label="Postnummer"
                                name="address.postalCode"
                                type="number"
                            />
                        </Grid>                
                        <Grid item xs={12} sm={6}>
                            <FormikTextField 
                                label="Ort"
                                name="address.postalAddress"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikTextField 
                                label="Land"
                                name="address.country"
                            />
                        </Grid>                                
                    </Grid>
                </Grid> 
*/}  
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom>
                        Koppling
                    </Typography>       
                    <Grid container spacing={3}>   
                        <Grid item xs={12} sm={6}>
                            <FormikTextField 
                                label="Företag"
                                name="company"
                            />
                        </Grid>
                    </Grid>
                </Grid>   
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom>
                        Betalsätt
                    </Typography>       
                        <List dense={true}>
                            {user?.paymentMethods.map(function(pm){
                                return (
                                    <ListItem key={pm.value}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {pm.type == 'CreditCard' ? <CreditCard /> : <Description />}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={pm.typeDescription} secondary={pm.valueDescription} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}
                        </List>
                        <VerifyPaymentMethod hideText={true} paymentMethodIsVerified={false} />
                </Grid>   
            </Grid>       
            <Button variant="contained" fullWidth color="primary" type="submit" value="Submit" className={classes.button}>
                Spara
            </Button>
        </Form>
    );
};