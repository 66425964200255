import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { default as React, useEffect, useState } from 'react';
import Typography from '../components/Typography';
import ImageHeroLayout from '../components/ImageHeroLayout';
import { Link } from 'react-router-dom';
import AuthService from 'services/auth.service';

const backgroundImage =
  'static/road.jpg';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
    marginTop: theme.spacing(1),
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
});

function StartPageHero(props) {
  const { classes } = props;
  const [currentUser, setCurrentUser] = useState();

  useEffect( () => {
      const user = AuthService.getCurrentUser();
      setCurrentUser(user);
  },[]); 

  return (
    <ImageHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Uppgradera din Söndag
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.more}>
        Hitta en bil nära dig.
      </Typography>
      {!currentUser && (
          <React.Fragment>
            <Button variant="contained"  size="large" color="primary" className={classes.button} component={Link} to="/signin" className={classes.button}>
                Logga in
            </Button>
            <Button variant="contained"  size="large" color="secondary" className={classes.button} component={Link} to="/signup" className={classes.button}>
                Bli medlem
            </Button>
          </React.Fragment>
        )}        
    </ImageHeroLayout>
  );
}

StartPageHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StartPageHero);