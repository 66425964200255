import {axiosInstance} from "../http-common";

const sendCode = (nr) => {
  return axiosInstance.post("/verifications/mobilePhoneNumber?mobilePhoneNumber=" + nr);
};

const verifyCode = (nr, code) => {
  return axiosInstance.post("/verifications/mobilePhoneNumber/Verify?mobilePhoneNumber=" + nr + "&code=" + code);
};

export default {
  sendCode,
  verifyCode
};