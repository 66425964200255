import Axios from "axios";
import authHeader from 'services/auth-header';
import AuthService from "services/auth.service";

export const axiosInstance = Axios.create({
    baseURL: "https://apix.netigrate.net/api/"
    //baseURL: "https://localhost:5001/api/"
    
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = { ...config.headers, Authorization: authHeader() };
    // you can also do other modification in config
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

axiosInstance.interceptors.response.use(function (response) {
    return response;
  }, async function (error) {
    if(error.response.status === 401) {
      var user = JSON.parse(localStorage.getItem('user'));
      if(user){
          await AuthService.refresh(user.auth_token, user.refresh_token);
          const originalRequest = error.config;
          if(!originalRequest._retry){
            originalRequest._retry = true;
            return axiosInstance(originalRequest);
          }
      }
      window.location.href = "/signin";
      localStorage.clear();
      return Promise.reject(error);
    }    

  });