import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import AuthService from "services/auth.service";

export function PrivateRoute ({component: Component, ...rest}) {
  const logginIn = AuthService.isLoggedIn();

    return (
      <Route
        {...rest}
        render={(props) => logginIn === true
          ? <Component {...props} />
          : <Redirect to={{pathname: '/signin', state: {from: props.location}}} />}
      />
    )
  }