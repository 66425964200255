// react
import {
    defaults as DefaultControls,

    ZoomSlider
} from 'ol/control';
import Feature from 'ol/Feature';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
// openlayers
import Map from 'ol/Map';
import 'ol/ol.css';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Icon, Style } from 'ol/style';
import View from 'ol/View';
import React, { useEffect, useRef, useState } from 'react';
import "./openLayerMap.css";

function SimpleMap(props) {

    const { point, ...other } = props;
    const [map, setMap] = useState();
    const [featuresLayer, setFeaturesLayer] = useState();

    const mapElement = useRef();

    const mapRef = useRef();
    mapRef.current = map;

    useEffect(() => {

        // create and add vector source layer
        const initalFeaturesLayer = new VectorLayer({
            source: new VectorSource()
        });

        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: 'https://osm-tiles2.netigrate.net/ng/{z}/{x}/{y}.png',
                    })
                }),
                initalFeaturesLayer

            ],
            view: new View({
                projection: 'EPSG:3857',
                center: [0, 0],
                zoom: 15,
                maxZoom: 18
            }),
            controls: DefaultControls().extend([
                new ZoomSlider()
            ])
        })

        setMap(initialMap);
        setFeaturesLayer(initalFeaturesLayer);

    }, []);

    useEffect(() => {

        if (props.point && featuresLayer) {
            var marker = new Feature({
                geometry: props.point
            });
            var icon = '/map-icon.png';
            if(props.icon == 'home'){
                icon= '/map-home.png';
            }
            marker.setStyle(new Style({
                image: new Icon({
                    crossOrigin: 'anonymous',
                    src: icon,
                    anchor: [0.5, 1]
                })
            }));
            var markers = [
                marker
            ]
            featuresLayer.setSource(
                new VectorSource({
                    features: markers
                })
            );

            map.getView().fit(featuresLayer.getSource().getExtent(), {
                padding: [100, 100, 100, 100]
            });
        }

    }, [point, featuresLayer]);

    return (
        <div ref={mapElement} style={{width: "100%", height: "600px"}} className="map-container"></div>
    )

}

export default SimpleMap