import { Box, Card, CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Alert from 'components/alert/alert';
import Background from 'components/Layout/Background';
import PageTitle from "components/Layout/PageTitle";
import "date-fns";
import { useVehicleData } from 'hooks/useVehicleData';
import BookCarFilter from 'pages/BookCar/BookCarFilters';
import { default as React, useState } from 'react';
import { Link } from 'react-router-dom';

export default function BookCarPage() {
    const {
        availableFuels,
        availableCarModels,
        availableFeatures,
        error,
      } = useVehicleData();
      const [message, setMessage] = useState(error);
      const [severity, setSeverity] = useState("error");

    return (
        <Container maxWidth="sm">
            <Background />
            <PageTitle title="Boka bil" />
            <Card>
                <CardContent>
                    <BookCarFilter fuels={availableFuels} carModels={availableCarModels} features={availableFeatures} onSearchChange={()=>{return}} />
                    <Box textAlign='center'>
                        <Button variant="contained" align="center" color="primary" component={Link} to="/carsnear">
                            Sök
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <Alert open={message!=null} severity={severity} onClick={() => setMessage(null)}>
                {message}
            </Alert>    
        </Container>
    );
}