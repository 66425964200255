import {axiosInstance} from "../http-common";

const getMyBookings = () => {
  return axiosInstance.get("/bookings/");
};

const get = (id) => {
  return axiosInstance.get("/bookings/" + id);
};

const commit = (id, data) => {
  if(id){
    return update(id,data);
  }
  return create(data);
};

const update = (id, data) => {
  return axiosInstance.put("/bookings/" + id, data);
};

const create = (data) => {
  return axiosInstance.post("/bookings/", data);
};

const bookingsByCarId = (carId) => {
  return axiosInstance.get("/bookings/byCar/" + carId);
};

const bookingsByCarIdWithCurrent = (carId, currentBookingId) => {
  return axiosInstance.get("/bookings/byCar/" + carId + "?currentBooking=" + currentBookingId);
};

export default {
  get,
  commit,
  getMyBookings,
  bookingsByCarId,
  bookingsByCarIdWithCurrent
};