import './custom.css'

import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import BookCarPage from "./pages/BookCar/BookCarPage"
import BookingsPage from "./pages/Bookings/BookingsPage"
import CarPage from "./pages/Car/CarPage"
import CarsNearPage from "./pages/CarsNear/CarsNearPage"
import Layout  from './components/Layout/Layout';
import NotFoundPage from "./pages/NotFoundPage"
import ProfilePage from "./pages/ProfilePage"
import SignInPage from "./pages/SignInPage"
import BookingPage from "./pages/BookingPage"
import SignUpPage from "./pages/SignUpPage"
import StartPage from "./pages/StartPage"
import VehicleHomePage from "./pages/VehicleHome/VehicleHomePage"
import {PrivateRoute} from "./components/PrivateRoute"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './components/theme';

export default function App () {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout>
          <Switch>
            <Route exact path='/' component={StartPage} />
            <Route path='/start' component={StartPage} />
            <Route path='/signup' component={SignUpPage} />
            <Route path='/signin' component={SignInPage} />
            <PrivateRoute path='/profile-page' component={ProfilePage} />
            <PrivateRoute path='/book-car' component={BookCarPage} />
            <PrivateRoute path='/bookings' component={BookingsPage} />
            <PrivateRoute path='/booking/:bookingId' component={BookingPage} />
            <PrivateRoute path='/car/:carId' component={CarPage} />
            <PrivateRoute path='/vehicleHome/:vehicleHomeId' component={VehicleHomePage} />
            <PrivateRoute path='/carsnear/:lat?/:lon?' component={CarsNearPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ThemeProvider>
    );
}
