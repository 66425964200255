import {axiosInstance} from "../http-common";
import wrapper from 'axios-cache-plugin'

const TIME_CHACHED = 1000 * 60 * 60 * 24 * 1

const getAll = () => {
  let httpProxy = wrapper(axiosInstance, {
    maxCacheSize: 15
  })
  return axiosInstance.get("vehicles/");
};

const get = (id) => {
  return axiosInstance.get("vehicles/" + id);
};

export default {
  get,
  getAll
};