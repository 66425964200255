import OpenLayerMap from 'components/maps/openLayerMap';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import { default as React, useEffect, useState } from 'react';
import PropTypes from "prop-types";

export default function VehicleHomeMap(props) {
    const [features, setFeatures] = useState([]);
    const { onMarkerClick } = props;

    useEffect(() => {
        if(props.vehicleHomes.length > 0){
            var markers = props.vehicleHomes.map(item => {
                var marker = new Feature({
                    geometry: new Point(fromLonLat([item.position.longitude, item.position.latitude]))
                });
                marker.set('id', item.id);
                marker.setStyle(new Style({
                    image: new Icon({
                        crossOrigin: 'anonymous',
                        src: '/map-icon.png',
                        anchor: [0.5, 1]
                    })
                }));
                return marker;
            })
            setFeatures(markers);
        }
    },[props.vehicleHomes]);

    const onClick = (data) => {
        onMarkerClick(data);
    };

    return (
        <OpenLayerMap features={features} userPosition={props.userPosition}  onMarkerClick={onClick} />
    );
}

VehicleHomeMap.propTypes = {
    onMarkerClick: PropTypes.func
};