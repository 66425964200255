import React, { Component } from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import Event from '@material-ui/icons/Event';
import Home from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import Person from '@material-ui/icons/Person';

export class FooterMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0
        };
    }
    
    render() {
        const stickToBottom = {
            width: "100%",
            position: "fixed",
            bottom: 0
        };

        return (
            <BottomNavigation
                style={stickToBottom}
                value={this.state.value}
                onChange={(event, newValue) => {
                    this.setState({ value: newValue });
                }}
                showLabels
                >
                <BottomNavigationAction label="Start" icon={<Home />} component={Link} to="/start" />
                <BottomNavigationAction label="Boka bil" icon={<DirectionsCar />} component={Link} to="/book-car" />
                <BottomNavigationAction label="Bokningar" icon={<Event />} component={Link} to="/bookings"  />
                <BottomNavigationAction label="Profil" icon={<Person />} component={Link} to="/profile-page" />
            </BottomNavigation>
        );
    }
}

export default FooterMenu;
