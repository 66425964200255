import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Alert from 'components/alert/alert';
import TabPanel from 'components/tabs/tabPanel';
import { a11yProps } from 'helpers/a11yProps';
import WithLoading from 'hoc/WithLoading';
import { default as React, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import BookingService from 'services/booking.service';
import ActiveBooking from './ActiveBooking';
import BookingList from './BookingList';
import BookingTable from './BookingTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 350,
  },
}));

export default function BookingTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [bookings, setBookings] = useState([]);
  const [oldBookings, setOldBookings] = useState([]);
  const [activeBooking, setActiveBooking] = useState(null);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("error")

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getBookings = () => {
    BookingService.getMyBookings()
      .then(response => {
        var planned = response.data.filter(function (x) {
          return x.status == "Planned";
        });
        var old = response.data.filter(function (x) {
          return x.status == "Archived";
        });
        var active = response.data.filter(function (x) {
          return x.status == "Active";
        });
        setBookings(planned);
        setOldBookings(old);
        if(active && active.length > 0){
          setActiveBooking(active[0]);
          setValue(2);
        }
        console.log(response.data);
        setMessage(null);
      })
      .catch(e => {
        setSeverity("error");
        setMessage("Problem att ansluta till servern, försök igen senare");
        console.log(e);
      });
  };

  useEffect( () => {
    getBookings();
  },[]);

  return (
    <span>
        <Box mb={2}>
          <AppBar position="static">
            <Tabs
                value={value}
                onChange={handleChange}
                centered
                variant="fullWidth"
            >
                <Tab label="Kommande" {...a11yProps(0)} />
                <Tab label="Genomförda" {...a11yProps(1)} />
                {activeBooking && <Tab label="Pågående" {...a11yProps(2)}/>}
            </Tabs>
          </AppBar>
        </Box>
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
              <WithLoading isLoading={bookings == null}>
                <BookingList bookings={bookings} />
              </WithLoading>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <WithLoading isLoading={oldBookings == null}>
              <Hidden smUp>
                  <BookingList showprice="true" bookings={oldBookings} />
                </Hidden>
                <Hidden only="sm">
                  <BookingTable bookings={oldBookings} />
                </Hidden>
            </WithLoading>
          </TabPanel>
          <React.Fragment>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <WithLoading isLoading={activeBooking == null}>
                <ActiveBooking booking={activeBooking} />
              </WithLoading>
            </TabPanel>
          </React.Fragment>
        </SwipeableViews>
        
        <Alert open={message!=null} severity={severity} onClick={() => setMessage(null)}>
            {message}
        </Alert>
    </span>
  );
}
