import { useState, useEffect } from 'react';
import BookingService from 'services/booking.service';
import { isWithinInterval, format, isSameDay, differenceInMinutes } from 'date-fns'

export const useVehicleAvailable = (carId, startTime, currentBookingId) => {
    const [availableData, setAvailableData] = useState({
        isAvailable: true,
        availableText: "",
        avaialableTime: new Date(),
        futureBookings: [],
        error: null
    });

    const getTimeFormat = (userDate, blockingDate) => {
        if(isSameDay(userDate, blockingDate)){
            return format(blockingDate, "HH:mm");
        }else{
            return format(blockingDate, "do MMM HH:mm");
        }
    }

    const joinCloseBookings = (bookingsAfter) => {
        var i;
        for(i = 0; i < bookingsAfter.length - 1; i++)
        {
            if(timeDiffLargerThanAllowed(bookingsAfter[0].stopTime, bookingsAfter[1].startTime)){
                bookingsAfter[1].startTime = bookingsAfter[0].startTime;
                var tmp = bookingsAfter[1];
                // Remove first two and and new combined value
                bookingsAfter.shift();
                bookingsAfter.shift();
                bookingsAfter.unshift(tmp);
            }
        }
        return bookingsAfter;
    }

    const timeDiffLargerThanAllowed = (from, to) => {
        var diff = differenceInMinutes(new Date(from), new Date(to));
        return Math.abs(diff) < 60;
    }

    useEffect(() => {
        if(!carId || carId == 0){
            return
        }
        var userStart = new Date(startTime);
        // Filter irrelevant bookings
        BookingService.bookingsByCarIdWithCurrent(carId, currentBookingId)
        .then(response => {
            var bookingsAfter = response.data.filter(function (x) {
                var stopDate = new Date(x.stopTime);
                return stopDate > userStart && x.currentBooking == false;
            });

            if(bookingsAfter && bookingsAfter.length > 0){

                bookingsAfter = joinCloseBookings(bookingsAfter);
                var firstbookingStart = new Date(bookingsAfter[0].startTime);
                var firstbookingStop = new Date(bookingsAfter[0].stopTime);

                // Check if our startingdate is within another booking
                if(isWithinInterval(userStart, { start: firstbookingStart, end: firstbookingStop}) || timeDiffLargerThanAllowed(userStart, firstbookingStart)){
                    setAvailableData({...availableData, 
                        isAvailable: false,
                        availableText: "Bokad till " + getTimeFormat(userStart, firstbookingStop),
                        avaialableTime: firstbookingStop,
                        futureBookings: [],
                        error: null
                    });
                }else{
                    var text = "Ledig till ";
                    setAvailableData({...availableData, 
                        isAvailable: true,
                        availableText: text + getTimeFormat(userStart, firstbookingStart),
                        avaialableTime: userStart,
                        futureBookings: [],
                        error: null
                    });
                }


            }else{
                setAvailableData({...availableData, 
                    isAvailable: true,
                    availableText: "Ledig",
                    avaialableTime: userStart,
                    futureBookings: [],
                    error: null
                });
            }
        })
        .catch(e => {
            setAvailableData({...availableData, error: "Could not load data"});
        });    

    }, [startTime]);

    return availableData;
}