import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import BookingItem from './BookingItem'

const BookingList = (props) => {
    return (
        <Grid container spacing={3}>
        {props.bookings &&
            props.bookings.map((bookingItem) => (
                <Grid item xs={12} sm={12} md={4} key={bookingItem.id}>
                    <BookingItem booking={bookingItem} showprice={props.showprice} />
                </Grid>
            ))}
        {!props.bookings && 
            <Card>
                <CardContent>
                    No bookings
                </CardContent>
            </Card>
        }
        </Grid>
    )
}
export default BookingList