import Fade from '@material-ui/core/Fade';
import PageTitle from 'components/Layout/PageTitle';
import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Background from 'components/Layout/Background'
import CarItem from "pages/Car/Caritem";

export default function BookingPage(props) {
    return (
        <Container maxWidth="lg">
            <Background />
            <PageTitle title="Ändra bokning" />
            <CarItem bookingId={props.match.params.bookingId} />
        </Container>
    );
}