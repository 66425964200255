import axios from "axios";

const API_URL = "https://apix.netigrate.net/api/";
//const API_URL = "https://localhost:5001/api/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "auth/login", {
        username,
        password
      })
      .then(response => {
        if (response.data.auth_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  refresh(auth_token, refresh_token) {
    return axios
      .post(API_URL + "auth/refreshtoken", {
        auth_token,
        refresh_token
      })
      .then(response => {
        if (response.data.auth_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  loginFacebook(accessToken) {
    return axios
      .post(API_URL + "externalauth/facebook", {
        accessToken
      })
      .then(response => {
        if (response.data.auth_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    var auth_token = JSON.parse(localStorage.getItem('user')).auth_token;
    var refresh_token = JSON.parse(localStorage.getItem('user')).refresh_token;
    axios
      .delete(API_URL + "auth/refreshtoken", {
        auth_token,
        refresh_token
      });
      localStorage.removeItem("user");
  }

  register(email, password, firstName, lastName) {
    return axios.post(API_URL + "Accounts", {
      email,
      password,
      firstName,
      lastName
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  isLoggedIn(){
    if (localStorage.getItem("user") === null) {
      return false;
    }
    return true;
  }
}

export default new AuthService();