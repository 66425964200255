import List from '@material-ui/core/List';
import VehicleItem from './VehicleItem';
import { default as React, useEffect, useState } from 'react';

const VehicleList = (props) => {
    const { vehicles, ...other } = props;

    return (
        <span>
            <List>
                {vehicles && vehicles.map((vehicle)=>
                    <VehicleItem key={vehicle.id} vehicle={vehicle} selected={vehicle.vehicleHome.id === props.selectedVehicleHomeId} />
                )}
            </List>
        </span>
    )
}
export default VehicleList