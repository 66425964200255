// react
import {
    defaults as DefaultControls,
    ZoomSlider
} from 'ol/control';
import { createEmpty, extend } from 'ol/extent.js';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
// openlayers
import Map from 'ol/Map';
import 'ol/ol.css';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import "./openLayerMap.css";
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Icon, Style } from 'ol/style';

// https://github.com/tcallsen/react-func-openlayers

export default function OpenLayerMap(props) {

    // set intial state
    const [map, setMap] = useState();
    const [featuresLayer, setFeaturesLayer] = useState();
    const [selectedCoord, setSelectedCoord] = useState();
    const { onMarkerClick } = props;

    // pull refs
    const mapElement = useRef();

    // create state ref that can be accessed in OpenLayers onclick callback function
    //  https://stackoverflow.com/a/60643670
    const mapRef = useRef();
    mapRef.current = map;

    // initialize map on first render - logic formerly put into componentDidMount
    useEffect(() => {

        // create and add vector source layer
        const initalFeaturesLayer = new VectorLayer({
            source: new VectorSource()
        });

        // create map
        const initialMap = new Map({
            target: mapElement.current,
            layers: [

                // USGS Topo
                new TileLayer({
                    source: new XYZ({
                        url: 'https://osm-tiles2.netigrate.net/ng/{z}/{x}/{y}.png',
                    })
                }),
                initalFeaturesLayer

            ],
            view: new View({
                projection: 'EPSG:3857',
                center: [0, 0],
                zoom: 15,
                maxZoom: 18
            }),
            controls: DefaultControls().extend([
                new ZoomSlider()
            ])
        })

        // set map onclick handler
        initialMap.on('click', handleMapClick);

        // save map and vector layer references to state
        setMap(initialMap);
        setFeaturesLayer(initalFeaturesLayer);

    }, []);

    // update map if features prop changes - logic formerly put into componentDidUpdate
    useEffect(() => {

        if (props.features.length && featuresLayer) { // may be null on first render

            var usePosition;
            if (props.userPosition?.lat && props.userPosition?.lon) { // may be null on first render

                usePosition = new Feature({
                    geometry: new Point(fromLonLat([props.userPosition.lon, props.userPosition.lat]))
                });
                usePosition.set('id', 0);
                usePosition.setStyle(new Style({
                    image: new Icon({
                        crossOrigin: 'anonymous',
                        src: '/map-home.png',
                        anchor: [0.5, 1]
                    })
                }));

            }
            // set features to map
            var markers = props.features;
            if(usePosition){
                markers.push(usePosition);
            }
            featuresLayer.setSource(
                new VectorSource({
                    features: markers // make sure features is an array
                })
            );

            // Zoom to fit top 2
            var i;
            var extent = new createEmpty();
            var featuresToZoom = 2;
            if(props.features.length < featuresToZoom){
                featuresToZoom = props.features.length;
            }
            for (i = 0; i < featuresToZoom; i++) {
                extend(extent, props.features[i].getGeometry().getExtent());
            }
            if(usePosition){
                extend(extent, usePosition.getGeometry().getExtent());
            }
            map.getView().fit(extent,  { size: map.getSize(), padding: [100,100,100,100] });
        }

    }, [props.features, props.userPosition]);


    const isCluster = (feature) => {
        if (!feature || !feature.get('features')) { 
            return false; 
        }
        return feature.get('features').length > 1;
    };

    // map click handler
    const handleMapClick = (event) => {
        var feature = mapRef.current.forEachFeatureAtPixel(event.pixel, 
            function(feature) { return feature; });
        if(feature){
            if (isCluster(feature))
            {
                var originalFeatures = feature.get('features');
                var extent = new createEmpty();
                // Zoom to fit all
                originalFeatures.forEach(function(f, index, array){
                    extend(extent, f.getGeometry().getExtent());
                });
                map.getView().fit(extent,  { size: map.getSize(), padding: [50,50,50,50] });
            } else {
                onMarkerClick(feature);
            }
        }
    }

    // render component
    return (
        <div ref={mapElement} style={{width: "100%", height: "600px"}} className="map-container"></div>
    )

}

OpenLayerMap.propTypes = {
    onMarkerClick: PropTypes.func.isRequired
};