import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { palette } from '@material-ui/system';

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function PageTitle(props) {
    document.title = props.title;

    return (
        <React.Fragment>
            <Box mb={2}>
                <Hidden mdUp>
                    <HideOnScroll {...props}>
                        <AppBar>
                            <Toolbar>
                                <Typography variant="h6">{props.title}</Typography>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Toolbar/>
                </Hidden>
                <Hidden smDown>
                    <Container maxWidth="lg">  
                        <Typography variant="h1" style={{color:"#fff"}} align="center" style={{marginTop:150}} gutterBottom>
                            {props.title}
                        </Typography>
                    </Container>
                </Hidden>
            </Box>
        </React.Fragment>
    );
}