
import React from 'react';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from '@material-ui/core/Snackbar';

const vertical = 'top';
const horizontal = 'center';

export default function Alert(props) {
    return (
        <Snackbar open={props.open} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }}>
            <MuiAlert elevation={6} variant="filled" {...props} />
        </Snackbar>   
    )
}