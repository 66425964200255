import { Chip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Done } from '@material-ui/icons';
import InfoDialog from 'components/dialogs/infoDialog';
import { default as React } from 'react';

export default function VerifySSN(props){
    const { socialSecurityNumberIsVerified: valueProp, ...other } = props;
    const [socialSecurityNumberIsVerified, setSocialSecurityNumberIsVerified] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    React.useEffect(() => {
        setSocialSecurityNumberIsVerified(valueProp);
    }, [valueProp]);

    return(
        <React.Fragment>
            {!socialSecurityNumberIsVerified
                &&
                <React.Fragment>
                    <Typography variant="body1" gutterBottom>
                        Godkänn vårt avtal med BankId
                    </Typography>
                    <Button variant="contained" color="primary" onClick={()=>setOpenDialog(true)}>Signera avtal med BankId</Button>
                    <InfoDialog open={openDialog} onClose={() => setOpenDialog(false)} onCancel={() => setOpenDialog(false)}   header="Signera avtal med BankId">
                        <React.Fragment>
                            <Typography variant="body1" gutterBottom>
                                Test test
                            </Typography>
                        </React.Fragment>
                    </InfoDialog>
                </React.Fragment>
            }
        </React.Fragment>
    );
}