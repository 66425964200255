import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'date-fns';
import PropTypes from "prop-types";
import React from 'react';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    chipspace: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: theme.spacing(2),
        "& > *": {
            margin: theme.spacing(0.5),
        }
    }
}));

export default function CarDetailsDialog(props) {
    const { car, onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);


    const handleOk = () => {
        onClose(value);
    };

    return (
        <Dialog
            maxWidth="xs"
            fullScreen={fullScreen}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogContent dividers>
                <Grid>
                    <Typography variant="h3" component="h2">{car?.vehicleModel.modelName}</Typography>
                    <Typography variant="h5" component="h2" gutterBottom>{car?.regNo}</Typography>
                    <img src={car?.vehicleModel?.imageUrls[0]} alt="" style={{ width: "100%" }} />
                    <span className={classes.chipspace}>
                        <Chip variant="outlined" color="primary" label={car?.vehicleModel.fuelType} />
                        {car?.features.map(function(feature){
                            return (<Chip variant="outlined" key={feature.tag} color="primary" label={feature.description} />)
                        })}
                    </span>
                </Grid>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {car?.generalInfo}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CarDetailsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};