import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import CarDetailsDialog from 'components/dialogs/carDetailsDialog';
import React from 'react';

const CarWithPopup = (props) => {
    var { car, ...other } = props;
    const [carDetailsOpen, setCarDetailsOpen] = React.useState(false);
    const closeCarDetails = () => {
        setCarDetailsOpen(false);
    };
    return (
        <React.Fragment>
            <ListItem key="vehicle" button onClick={()=>setCarDetailsOpen(true)}>
                <ListItemIcon>
                    <DirectionsCarOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={car?.vehicleModel?.modelName} secondary={car?.regNo} />
            </ListItem>
            <CarDetailsDialog
                id="car-dialog"
                keepMounted
                open={carDetailsOpen}
                onClose={closeCarDetails}
                car={car}
            />
        </React.Fragment>
    )
}
export default CarWithPopup