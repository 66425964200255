import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from "prop-types";
import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

export default function FilterDialog(props) {
    const { onClose, open, features, featureValue: featureValueProp, fuels, fuelValue:fuelValueProp, modelsValue:modelsValueProp, carModels, ...other } = props;
    const [featureValue, setFeatureValue] = React.useState("");
    const [fuelValue, setFuelValue] = React.useState("");
    const [modelsValue, setModelsValue] = React.useState([]);
    const radioGroupRef = React.useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (!open) {
            setFuelValue(fuelValueProp);
        }
    }, [fuelValueProp, open]);

    React.useEffect(() => {
        if (!open) {
            setFeatureValue(featureValueProp);
        }
    }, [featureValueProp, open]);

    React.useEffect(() => {
        if (!open) {
            setModelsValue(modelsValueProp);
        }
    }, [modelsValueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(featureValue, fuelValue, modelsValue);
    };

    const handleChange = (event) => {
        setFeatureValue(event.target.value);
    };

    const handleFuelChange = (event) => {
        setFuelValue(event.target.value);
    };

    const handleModelsChange = (event) => {
        if(event.target.checked){
            setModelsValue([...modelsValue, event.target.name]);
        }else{
            setModelsValue(modelsValue.filter(item=>item != event.target.name));
        }
        
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            fullScreen={fullScreen}
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">Filter</DialogTitle>
            <DialogContent dividers>
                <FormLabel component="legend">Bränsle</FormLabel>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="fuels"
                    name="fuels"
                    value={fuelValue}
                    onChange={handleFuelChange}
                >
                    <FormControlLabel
                            value=""
                            key="Alla"
                            control={<Radio />}
                            label="Alla"
                        />
                    {fuels?.map((option) => (
                        <FormControlLabel
                            value={option}
                            key={option}
                            control={<Radio />}
                            label={option}
                        />
                    ))}
                </RadioGroup>
                <FormLabel component="legend">Bilmodeller</FormLabel>
                <FormGroup
                    ref={radioGroupRef}
                    aria-label="other"
                    name="other"
                    value={modelsValue}
                    onChange={handleModelsChange}
                >
                    {carModels?.map((option) => (
                        <FormControlLabel
                            value={option}
                            key={option}
                            name={option}
                            checked={modelsValue.includes(option)}
                            control={<Checkbox />}
                            label={option}
                        />
                    ))}
                </FormGroup>    
                <FormLabel component="legend">Övrigt</FormLabel>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="other"
                    name="other"
                    value={featureValue}
                    onChange={handleChange}
                >
                    <FormControlLabel
                            value=""
                            key="Alla"
                            control={<Radio />}
                            label="Alla"
                        />
                    {features.map((option) => (
                        <FormControlLabel
                            value={option.description}
                            key={option.description}
                            control={<Radio />}
                            label={option.description}
                        />
                    ))}
                </RadioGroup>                            
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

FilterDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    featureValue: PropTypes.array.isRequired,
    fuelValue: PropTypes.string.isRequired,
    modelsValue: PropTypes.array.isRequired
};