import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MapIcon from "@material-ui/icons/Map";
import TuneIcon from "@material-ui/icons/Tune";
import FilterDialog from "components/dialogs/filterDialog";
import WhenDialog from "components/dialogs/whenDialog";
import WhereDialog from "components/dialogs/whereDialog";
import {usePosition} from 'hooks/usePosition'

import "date-fns";
import Moment from 'moment';
import React from "react";
import {useEffect} from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    paper: {
        minWidth: 400,
    }
}));

export default function BookCarFilter(props) {
    Moment.locale('sv');
    const {
        latitude,
        longitude,
        timestamp,
        accuracy,
        speed,
        error,
      } = usePosition();
    const { fuels, carModels, features, onSearchChange, ...other } = props;
    const classes = useStyles();
    const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
    const [openDateTimeDialog, setOpenDateTimeDialog] = React.useState(false);
    const [openWhereDialog, setOpenWhereDialog] = React.useState(false);

    const storedSearch = JSON.parse(localStorage.getItem('storedSearch'))
    const [selectedSearch, setSelectedSearch] = React.useState(storedSearch ?? {
        startDate: Date.now(),
        lat: latitude,
        lon: longitude,
        posName: "Din position",
        features: [],
        carModels: [],
        fuel: ""
    });

    const handleClickFilter = () => {
        setOpenFilterDialog(true);
    };

    const handleCloseFilter = (newFeatureValue, newFuelValue, newModelsValue) => {
        setOpenFilterDialog(false);
        if(newFeatureValue || newFuelValue || newModelsValue){
            setSelectedSearch({...selectedSearch, 
                features: newFeatureValue,
                fuel: newFuelValue,
                carModels: newModelsValue
            });
        }
    };

    const handleCloseWhen = (newValue) => {
        setOpenDateTimeDialog(false);

        if (newValue) {
            setSelectedSearch({...selectedSearch, startDate: newValue});
        }
    };

    const handleCloseWhere = (lat,lon, name) => {
        setOpenWhereDialog(false);
        if(lat && lon && name){
            setSelectedSearch({...selectedSearch, 
                lat: lat,
                lon: lon,
                posName: name
            });
        }
    };

    useEffect(() => {
        localStorage.setItem('storedSearch', JSON.stringify(selectedSearch));
        onSearchChange(selectedSearch);
    }, [selectedSearch]);

    useEffect(() => {
        var storedDate = new Date(storedSearch?.startDate);
        if(storedDate < new Date()){
            setSelectedSearch({...selectedSearch, startDate: new Date()});
        }
    }, []);

    return (
        <List component="div" role="list" style={{minWidth:"300px"}}>
            <ListItem
                button
                divider
                aria-haspopup="true"
                onClick={()=>setOpenDateTimeDialog(true)}
                role="listitem"
            >
                <ListItemIcon>
                    <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary="När" secondary={Moment(selectedSearch.startDate).format('D MMM - HH:mm')} />
            </ListItem>
            <WhenDialog
                classes={{
                    paper: classes.paper
                }}
                id="when-dialog"
                keepMounted
                open={openDateTimeDialog}
                onClose={handleCloseWhen}
                value={new Date(selectedSearch.startDate)}
            />
            <ListItem button divider role="listitem" onClick={()=>setOpenWhereDialog(true)}>
                <ListItemIcon>
                    <MapIcon />
                </ListItemIcon>
                <ListItemText primary="Vart" secondary={selectedSearch.posName} />
            </ListItem>
            <WhereDialog
                classes={{
                    paper: classes.paper
                }}
                id="where-dialog"
                keepMounted
                open={openWhereDialog}
                onClose={handleCloseWhere}
                selectedLat={selectedSearch.lat}
                selectedLon={selectedSearch.lon}
                selectedName={selectedSearch.posName}
            />
            <ListItem
                button
                divider
                aria-haspopup="true"
                onClick={handleClickFilter}
                role="listitem"
            >
                <ListItemIcon>
                    <TuneIcon />
                </ListItemIcon>
                <ListItemText primary="Filter" secondary={[selectedSearch.carModels, selectedSearch.features, selectedSearch.fuel].filter(p=>p?.length > 0).join(", ")} />
            </ListItem>
            <FilterDialog
                classes={{
                    paper: classes.paper
                }}
                id="filter-dialog"
                keepMounted
                fuels={fuels}
                carModels={carModels}
                features={features}
                open={openFilterDialog}
                onClose={handleCloseFilter}
                featureValue={selectedSearch.features}
                fuelValue={selectedSearch.fuel}
                modelsValue={selectedSearch.carModels}
            />
        </List>
    )
}