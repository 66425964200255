import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { differenceInHours, format } from 'date-fns'

const BookingTable = (props) => {
    var { bookings, ...other } = props;

    return (
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">RegNo</TableCell>
              <TableCell align="right">Start</TableCell>
              <TableCell align="right">Slut</TableCell>
              <TableCell align="right">Timmar (h)</TableCell>
              <TableCell align="right">Sträcka (km)</TableCell>
              <TableCell align="right">Pris</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="right"><Link to={"/car/" + row.vehicleId}>{row.regNo}</Link></TableCell>
                <TableCell align="right">{format(new Date(row.startTime), "yyyy-MM-dd HH:mm")}</TableCell>
                <TableCell align="right">{format(new Date(row.stopTime), "yyyy-MM-dd HH:mm")}</TableCell>
                <TableCell align="right">{differenceInHours(new Date(row.stopTime),new Date(row.startTime))}</TableCell>
                <TableCell align="right">{row.distance}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
}
export default BookingTable