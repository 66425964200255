import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TuneIcon from '@material-ui/icons/Tune';
import Alert from 'components/alert/alert';
import VehicleList from "./VehicleList";
import Background from 'components/Layout/Background';
import PageTitle from 'components/Layout/PageTitle';
import { default as React, useEffect, useState } from 'react';
import VehicleHomeService from 'services/vehicleHome.service';
import VehicleHomeMap from 'pages/CarsNear/VehicleHomeMap'

const useStyles = makeStyles((theme) => ({
    vehicleList: {
        overflow: 'hidden',
        backgroundColor:"white",
        [theme.breakpoints.down('sm')]: {
            overflowY: 'scroll',
            height: '30vh'
        }
    },
    carpoolMap: {
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            overflow: 'hidden',
            height: 'calc(70vh - 150px)'
        },
        
    }
}));
  
export default function VehicleHomePage(props) {

    const [vehicles, setVehicles] = useState([]);
    const [vehicleHomes, setVehicleHomes] = useState([]);
    const [selectedVehicleHomeId, setSelectedVehicleHomeId] = useState(0);
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState("error");
    const classes = useStyles();

    const getCarsInHome = (id) => {
        VehicleHomeService.getVehiclesInHome(id)
            .then(response => {
                setVehicles(response.data);
                setMessage(null);
            })
            .catch(e => {
                handleError(e);
            });
    };

    const getVehicleHomes = (id) => {
        VehicleHomeService.getById(id)
            .then(response => {
                setVehicleHomes([response.data]);
                setMessage(null);
            })
            .catch(e => {
                handleError(e);
            });
    };

    const handleError = (e) => {
        setSeverity("error");
        setMessage("Problem att ansluta till servern, försök igen senare");
        console.log(e);
    }

    useEffect( () => {
        getCarsInHome(props.match.params.vehicleHomeId);
        getVehicleHomes(props.match.params.vehicleHomeId);
      },[]);
    
    const onMarkerClick = (data) => {
        setSelectedVehicleHomeId(data.get('id'));
    };

    return (
        <Container maxWidth="lg">
            <Background />
            <PageTitle title="Välj bil" />
            <Grid container>
                <Grid item md={4} xs={12} className={classes.vehicleList}>
                    <VehicleList vehicles={vehicles} selectedVehicleHomeId={selectedVehicleHomeId} />
                </Grid>
                <Grid item md={8} xs={12} className={classes.carpoolMap}>
                    <VehicleHomeMap vehicleHomes={vehicleHomes} onMarkerClick={onMarkerClick} />
                </Grid>
            </Grid>
            <Alert open={message!=null} severity={severity} onClick={() => setMessage(null)}>
                {message}
            </Alert>                
        </Container>
    );
}

