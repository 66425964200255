import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function WithLoading(props) {
    if (!props.isLoading) return (<span>{props.children}</span>);
    return (
        <span style={{display: 'flex', justifyContent: 'center', marginTop:'100px'}}>
            <CircularProgress />
        </span>
    );
}
export default WithLoading;