import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert, AlertTitle } from '@material-ui/lab';
import CustomAlert from 'components/alert/alert';
import SimpleMap from 'components/maps/simpleMap';
import 'date-fns';
import WithLoading from 'hoc/WithLoading';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import PropTypes from "prop-types";
import { default as React, useState } from 'react';
import VehicleHomeService from 'services/vehicleHome.service';

const useStyles = makeStyles((theme) => ({
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
  }));

export default function CarpoolDetailsDialog(props) {
    const { onClose,open, vehicleHomeId, ...other } = props;
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [vehicleHome, setVehicleHome] = useState(null);
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState("error")

    const handleOk = () => {
        onClose();
    };

    React.useEffect(() => {
        if(open){
            VehicleHomeService.getById(vehicleHomeId)
            .then(response => {
                setVehicleHome(response.data);
              })
              .catch(e => {
                setSeverity("error");
                setMessage("Problem att ansluta till servern, försök igen senare");
                console.log(e);
              });
        }
    }, [vehicleHomeId, open]);

    return (
        <span>
            <Dialog
                maxWidth="md"
                fullScreen={fullScreen}
                fullWidth={true}
                aria-labelledby="confirmation-dialog-title"
                open={open}
                {...other}
            >
                <DialogContent dividers>
                    <WithLoading isLoading={vehicleHome == null}>
                        <Typography variant="h3" component="h2">{vehicleHome?.name}</Typography>
                        <Typography variant="h5" component="h2" gutterBottom>{vehicleHome?.street + " - " + vehicleHome?.postalAdress }</Typography>     
                        {vehicleHome?.bookingInfo && 
                            <Box marginTop={2} marginBottom={2} >
                                <Alert severity="info">
                                    <AlertTitle>Viktig information</AlertTitle>
                                    {vehicleHome?.bookingInfo}
                                </Alert>
                            </Box>
                        }
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                {vehicleHome?.wayFinding && 
                                    <span>
                                        <Typography variant="h6" gutterBottom>Hitta hit</Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {vehicleHome?.wayFinding}
                                        </Typography>    
                                        <Typography variant="h6" gutterBottom>Bilder</Typography>
                                        <GridList className={classes.gridList} cols={2.5}>
                                            {vehicleHome?.imageUrls && vehicleHome?.imageUrls.map((url, index) => (
                                                <GridListTile key={index}>
                                                    <img src={url} alt={"poolImg" + index} />
                                                </GridListTile>
                                            ))}
                                        </GridList>                            
                                    </span>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                { vehicleHome?.position?.longitude && 
                                    <span>
                                        <Typography variant="h6" gutterBottom>Karta</Typography>
                                        <SimpleMap point={new Point(fromLonLat([vehicleHome?.position.longitude, vehicleHome?.position.latitude]))} />
                                    </span>
                                }
                            </Grid>
                        </Grid>
                    </WithLoading>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="default" target="_blank" href={"https://maps.google.com?daddr=" + vehicleHome?.position.latitude + "," + vehicleHome?.position.longitude}>Öppna i karta</Button>
                    <Button onClick={handleOk} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomAlert open={message!=null} severity={severity} onClick={() => setMessage(null)}>
                {message}
            </CustomAlert>
        </span>
    );
}

CarpoolDetailsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};