import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import 'date-fns';
import PropTypes from "prop-types";
import React from 'react';
import {useState} from 'react';
import NominatimService from 'services/nominatim.service'
import SimpleMap from 'components/maps/simpleMap';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

export default function WhereDialog(props) {
    const { onClose, open, selectedLat: selectedLatProp, selectedLon: selectedLonProp, selectedName: selectedNameProp,  ...other } = props;
    const [selectedLat, setSelectedLat] = React.useState(selectedLatProp);
    const [selectedLon, setSelectedLon] = React.useState(selectedLonProp);
    const [selectedName, setSelectedName] = React.useState(selectedNameProp);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (!open) {
            setSelectedLat(selectedLatProp);
        }
    }, [selectedLatProp, open]);

    React.useEffect(() => {
        if (!open) {
            setSelectedLon(selectedLonProp);
        }
    }, [selectedLonProp, open]);

    React.useEffect(() => {
        if (!open) {
            setSelectedName(selectedNameProp);
        }
    }, [selectedNameProp, open]);

    const SearchButton = () => (
        <IconButton onClick={findAddress}>
          <SearchIcon />
        </IconButton>
    )

    const findAddress = e => {
        e.preventDefault()
        NominatimService.search(selectedName)
        .then(response => {
            if(response.length > 0){
                setSelectedLat(response[0].lat);
                setSelectedLon(response[0].lon);
            }
        })
        .catch(e => {
            alert(JSON.stringify(e));
        });
    }

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(selectedLat,selectedLon, selectedName);
    };
    
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            fullScreen={fullScreen}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title" color="primary">Vart</DialogTitle>
            <DialogContent dividers>
                <TextField
                    id="seachString"
                    label="Hitta adress"
                    fullWidth
                    onChange={(e)=>setSelectedName(e.target.value)}
                    value={selectedName}
                    InputProps={{endAdornment: <SearchButton />}}
                />
                <SimpleMap point={new Point(fromLonLat([selectedLon, selectedLat]))} icon="home" />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Avbryt
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

WhereDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedLat: PropTypes.string.isRequired,
    selectedLon: PropTypes.string.isRequired
};