import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CreditCard, Description } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import UpdateIcon from '@material-ui/icons/Update';
import Moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const BookingItem = (props) => {
    Moment.locale('sv');
    const { booking, car, ...other } = props;

    return (
        <span>
            {booking ? (
                <Card>
                    <CardMedia
                        style={{padding: "20px"}}
                        component="img"
                        image={booking?.imageUrls[0]}
                        title="Car"
                    />
                    <CardContent>
                        <List>
                            <ListItem key="vechicle">
                                <ListItemIcon>
                                    <DirectionsCarOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={ booking.vehicleModel } secondary={booking.regNo} />
                            </ListItem>
                            <Divider />
                            <ListItem key="vehicleHome">
                                <ListItemIcon>
                                    <LocationOnOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={booking.street} secondary={booking.city} />
                            </ListItem>
                            <Divider />
                            <ListItem key="start">
                                <ListItemIcon>
                                    <AccessTimeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Hämta" secondary={Moment(booking.startTime).format('D MMM - HH:mm')} />
                            </ListItem>
                            <Divider />
                            <ListItem key="stop">
                                <ListItemIcon>
                                    <UpdateIcon />
                                </ListItemIcon>
                                <ListItemText primary="Lämna" secondary={Moment(booking.stopTime).format('D MMM - HH:mm')} />
                            </ListItem>
                            <ListItem key="paymentMethod">
                                <ListItemIcon>
                                    {booking.paymentMethod.type == 'Creditcard' ? <CreditCard /> : <Description />}
                                </ListItemIcon>
                                <ListItemText primary="Payment method" secondary={booking.paymentMethod.valueDescription} />
                            </ListItem>
                            {booking.driverNote && (
                                <React.Fragment>
                                    <Divider />
                                    <ListItem key="note">
                                        <ListItemIcon>
                                            <SubjectIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Notering" secondary={booking.driverNote} />
                                    </ListItem>
                                </React.Fragment>
                            )}

                            {props.showprice && (
                                <span>
                                    <Divider variant="inset" component="li" />
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocalOfferOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                new Intl.NumberFormat("sv-SE", {
                                                    style: "currency",
                                                    currency: "SEK"
                                                }).format(booking.price)
                                            }
                                            secondary={booking.distance + " km"} />
                                    </ListItem>
                                </span>
                            )}
                        </List>
                    </CardContent>
                    {!props.showprice && (
                    <CardActions>
                        <Button size="small" color="primary" variant="contained" component={Link} to={"/booking/" + booking.id}>
                            Edit
                        </Button>
                        <Button size="small" color="primary">
                            Delete
                        </Button>
                    </CardActions>
                    )}
                </Card>
            ) : null}
        </span>
    )
}
export default BookingItem