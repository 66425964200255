import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Alert from 'components/alert/alert';
import { UserForm } from 'components/forms/userForm';
import Background from 'components/Layout/Background';
import AuthService from 'services/auth.service';
import PageTitle from 'components/Layout/PageTitle';
import { Formik } from 'formik';
import WithLoading from 'hoc/WithLoading';
import { default as React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserService from 'services/user.service';
import { userValidation } from 'validation/userValidation';
import VerifyMobile from 'components/verification/VerifyMobile';
import VerifyAlert from 'components/verification/VerifyAlert';
import CardActions from '@material-ui/core/CardActions';


const useStyles = makeStyles(styles)

function ProfilePage(props) {

    const [currentAccount, setCurrentAccount] = useState();
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState("error");
    const history = useHistory();
    const classes = useStyles();

    const getUser = id => {
        UserService.get()
          .then(response => {
            setCurrentAccount(response.data);
            console.log(response.data);
            setMessage(null);
          })
          .catch(e => {
            setSeverity("error");
            setMessage("Problem att ansluta till servern, försök igen senare");
            console.log(e);
          });
    };

    const updateUser = (user) => {
        UserService.update(user.id, user)
          .then(response => {
            console.log(response.data);
            setSeverity("success");
            setMessage("Vi har sparat dina uppgifter!");
          })
          .catch(e => {
            console.log(e);
            setSeverity("error");
            setMessage("Tyvärr misslyckades vi att spara dina uppgifter, försök igen");
          });
    };

    useEffect(() => {
        getUser();
    },[]); 

    

    const handleLogout = () => {
        AuthService.logout();
        history.push("/signin");
    }

    return (
        <React.Fragment>
            <Background />
            <PageTitle title="Profil" />
            <Container maxWidth="md">
                <Box mb={2}>
                    <VerifyAlert user={currentAccount} />
                </Box>
                <Card>
                    <CardContent>
                        <WithLoading isLoading={currentAccount == null}>
                            <span>
                                <Formik
                                    initialValues={currentAccount}
                                    enableReinitialize={true} 
                                    validationSchema={userValidation}
                                    onSubmit={(values) => {
                                        updateUser(values);
                                    }}
                                >
                                    <UserForm user={currentAccount} />
                                </Formik>
                                <Button color="primary" fullWidth onClick={handleLogout}>
                                    Sign out
                                </Button>
                            </span>
                        </WithLoading>
                        <Alert open={message!=null} severity={severity} onClick={() => setMessage(null)}>
                            {message}
                        </Alert>
                    </CardContent>
                </Card>

            </Container>
        </React.Fragment>
    );
}

export default ProfilePage;