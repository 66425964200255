import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SimpleHero from 'components/SimpleHero';
import InfoBoxes from 'components/InfoBoxes';
import InfoCard from 'components/InfoCard';
import React from 'react';
import StartPageHero from 'views/StartPageHero';

export default function StartPage() {
    return (
        <span>
                <StartPageHero />
                <InfoBoxes />
                <SimpleHero header="Boka bil enkelt" text="Vi hjälper er med ett system för enkel bilbokning" />
                <Container maxWidth="md">
                    <Grid container spacing={5} alignItems="flex-end">
                        <InfoCard title="Enkelt" subheader="Boka bil enkelt" buttonText="Visa mig">
                            Med Bilbokaren är det enkelt att hitta en ledig bil
                        </InfoCard>
                        <InfoCard title="Flexibelt" subheader="Flera sorters hårvara" buttonText="Visa mig">
                            Det går att använda flera olika hårdvaror för att kommunicera med bilarna.
                        </InfoCard>
                        <InfoCard title="Roligt" subheader="Skoj att boka bil" buttonText="Ha skoj">
                            Det är roligt när det är så enkelt att boka bil
                            <ul>
                                <li>Snabb bokning</li>
                                <li>Enkel hantering av nycklar</li>
                            </ul>
                        </InfoCard>
                    </Grid>
                </Container>
                <img src="./banner.svg" />
        </span>
    );
}