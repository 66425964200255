import Hidden from '@material-ui/core/Hidden';
import Header from "components/Layout/Header/Header.js";
import HeaderLinks from "components/Layout/Header/HeaderLinks.js";
import SignInLinks from "components/Layout/Header/SignInLinks.js";
import { default as React, useEffect, useState } from 'react';
import AuthService from 'services/auth.service';
import ErrorBoundary from '../ErrorBoundary';
import FooterMenu from 'components/Layout/FooterMenu';

export default function Layout(props) {
    const [currentUser, setCurrentUser] = useState();

    useEffect( () => {
      const user = AuthService.getCurrentUser();
      setCurrentUser(user);
    },[]); 

    return (
      <div>
        {currentUser ? (
          <Hidden smDown>
              <Header
                  color="transparent"
                  brand="Bilbokaren"
                  rightLinks={<HeaderLinks />}
                  fixed
                  changeColorOnScroll={{
                  height: 150,
                  color: "primary"
                  }}
              />   
          </Hidden>
        ) : (
          <Hidden>
              <Header
                  color="transparent"
                  brand="Bilbokaren"
                  rightLinks={<SignInLinks />}
                  fixed
                  changeColorOnScroll={{
                  height: 150,
                  color: "primary"
                  }}
              />   
          </Hidden>
        )}         
        <ErrorBoundary>
          <div style={{marginBottom: "60px"}}>
            {props.children}
          </div>
        </ErrorBoundary>
        {currentUser && (
        <Hidden mdUp>
            <FooterMenu />
        </Hidden>
        )}
      </div>
    );
}
