import axios from "axios";

const API_URL = "https://nominatim.openstreetmap.org/";

class NominatimService {
  search(data) {
    return axios
      .post(API_URL + "search?country=Sverige&limit=1&format=json&q=" + data)
      .then(response => {
        return response.data;
      });
  }
}

export default new NominatimService();