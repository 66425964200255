import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';
import { default as React, useEffect, useState } from 'react';
import VerifyMobile from './VerifyMobile';
import VerifyPaymentMethod from './VerifyPaymentMethod';
import VerifySSN from './VerifySSN';
import UserService from 'services/user.service'

export default function VerifyAlert(props){
    const { user, ...other } = props;
    const [showAlert, setShowAlert] = useState(false);

    React.useEffect(() => {
        setShowAlert(!UserService.userIsVerified(user));
    }, [user]);

    return(
        <React.Fragment>
            {showAlert &&
                <Alert severity="error">
                    <AlertTitle>Du måste komplettera din profil innan du kan boka bil</AlertTitle>
                    <Box mb={2}>
                        <VerifySSN socialSecurityNumberIsVerified={user?.verifications.socialSecurityNumberIsVerified} />
                    </Box>
                    <Box mb={2}>
                        <VerifyMobile mobilePhoneNumberIsVerified={user?.verifications.mobilePhoneNumberIsVerified} />
                    </Box>
                    <Box mb={2}>
                        <VerifyPaymentMethod paymentMethodIsVerified={user?.verifications.paymentMethodIsVerified} />
                    </Box>
                </Alert>
            }
        </React.Fragment>
    );
}