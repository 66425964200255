import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function NotFoundPage() {

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Typography component="h1" variant="h5">
                404 - Page not found :(
            </Typography>
        </Container>
    );
}