import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from "prop-types";
import { default as React } from 'react';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    chipspace: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(0.5),
        }
    }
}));

export default function InfoDialog(props) {
    const { onClose, onCancel,open, removeContainer, ...other } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const handleCancel = () => {
        onCancel();
    };

    const handleOk = () => {
        onClose();
    };

    return (
        <Dialog
            maxWidth="md"
            fullScreen={fullScreen}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title" color="primary">{props.header}</DialogTitle>
            {removeContainer ? 
                <React.Fragment>
                    {props.children}
                </React.Fragment>
                :
                <DialogContent dividers>
                    {props.children}
                </DialogContent>
            }

            <DialogActions>
                <Grid container justify="flex-end">
                    <Button onClick={handleCancel} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        Ok
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

InfoDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};