import { Input, InputLabel, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InfoDialog from 'components/dialogs/infoDialog';
import VerificationService from 'services/verification.service';
import { default as React } from 'react';

export default function VerifyMobile(props){
    const { mobilePhoneNumberIsVerified: valueProp, hideText, ...other } = props;
    const [mobilePhoneNumberIsVerified, setMobilePhoneNumberIsVerified] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [phoneNr, setPhoneNr] = React.useState("");
    const [code, setCode] = React.useState("");

    React.useEffect(() => {
        setMobilePhoneNumberIsVerified(valueProp);
    }, [valueProp]);

    const sendCode = () =>{
        if(phoneNr?.length > 0){
            VerificationService.sendCode(phoneNr);
            alert("Verification sent code to " + phoneNr);
        }
    }

    const onOk = () => {
        VerificationService.verifyCode(phoneNr, code);
        if(code?.length > 0){
            VerificationService.verifyCode(phoneNr, code)              
            .then(response => {
                if(response.data == true){
                    setOpenDialog(false);
                    setMobilePhoneNumberIsVerified(true);
                    alert("Phone nr verified");
                }else{
                    alert("Invalid code");
                }
            })
              .catch(e => {
                alert("Could not verify code");
            });

        }
    }

    return(
        <React.Fragment>
            {!mobilePhoneNumberIsVerified
                &&
                <React.Fragment>
                    {!hideText &&
                        <Typography variant="body1" gutterBottom>
                            För att kunna använda vår tjänst behöver vi ett giltigt telefonnummer. Vänligen verifiera ditt telefonnummer.
                        </Typography>
                    }
                    <Button variant="contained" color="primary" onClick={()=>setOpenDialog(true)}>Verifiera ditt telefonnummer</Button>
                    <InfoDialog open={openDialog} onClose={onOk} onCancel={() => setOpenDialog(false)}   header="Lägg till mobilnummer">
                        <React.Fragment>
                            <Typography variant="body1" gutterBottom>
                                Fyll i ditt telefonnummer nedan så skickar vi dig en verifieringskod via sms.
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="verify-phone">Telefonnummer</InputLabel>
                                <Input
                                    name="verify-phone"
                                    id="verify-phone"
                                    value={phoneNr}
                                    onChange={(e)=>setPhoneNr(e.target.value)}
                                />
                            </FormControl>
                            <Button variant="contained" color="primary" onClick={sendCode}>Skicka verifieringskod</Button>
                            <FormControl fullWidth>
                                <TextField
                                    error={code?.length != 5}
                                    id="verify-phone-code"
                                    label="Verifierings kod"
                                    defaultValue={code}
                                    helperText="Fyll i din 5 siffriga kod"
                                    onChange={(e)=>setCode(e.target.value)}
                                />
                            </FormControl>
                        </React.Fragment>
                    </InfoDialog>
                </React.Fragment>
            }
        </React.Fragment>
    );
}