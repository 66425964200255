import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import 'date-fns';
import { useVehicleAvailable } from 'hooks/useVehicleAvailable';
import React, { useEffect, useState } from 'react';

const AvailableText = (props) => {
    var { vehicleId, dateTime, note, show, currentBookingId, ...other } = props;
    const [selectedDateTime, setSelectedDateTime] = useState();
    const [selectedVehicleId, setSelectedVehicleId] = useState();
    const [visible, setVisible] = useState(true);

    const {
        isAvailable,
        availableText,
        futureBookings,
        error
      } = useVehicleAvailable(selectedVehicleId, selectedDateTime, currentBookingId);

      
    useEffect(() => {
        setSelectedDateTime(dateTime);
        setSelectedVehicleId(vehicleId);
    }, [dateTime, vehicleId]);

    useEffect(() => {
        if(show){
            if(show==="ifBooked"){
                setVisible(!isAvailable);
            }
            if(show==="ifAvailable"){
                setVisible(isAvailable);
            }
        }
    }, [show, isAvailable]);

    return (
        <React.Fragment>
            {
                visible && <Chip icon={isAvailable ? <DoneIcon /> : <CloseIcon />} variant="outlined" color="primary" label={availableText} />
            }
        </React.Fragment>
    )
}
export default AvailableText